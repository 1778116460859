import React from 'react'
import { Row, Column } from '../../components/Grid/Grid'
import { VAlign } from '../../enums/align'
import { map } from 'rambdax'
import { NewsEntity } from '../../types/news'
import NewsItem from './NewsItem'

export type NewsProps = {
  list: NewsEntity[]
  itemClassName?: string
  listClassName?: string
  isLatest?: boolean
}
export function News(props: NewsProps) {
  const { list, itemClassName, listClassName, isLatest } = props
  return (
    <Row vAlign={VAlign.STRETCH} className={listClassName}>
      {map(
        item => (
          <Column size={12} lg={4} key={item.id} className={itemClassName}>
            <NewsItem item={item} isLatest={isLatest} />
          </Column>
        ),
        (list || []) as NewsEntity[]
      )}
    </Row>
  )
}
