import { Container, Row, Column } from '../../components/Grid/Grid'
import Icon from '../../components/Icon/Icon'
import FreeText from '../../components/FreeText/FreeText'
import Heading from '../../components/Heading/Heading'
import Whitespace from '../../components/Whitespace/Whitespace'
import { MEDIA_QUERY_MOBILE, useMediaQuery } from '../../hooks/useMediaQuery'
import { getIconByName } from '../../icons'
import { resolveAsImage } from '../../resolvers'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GatsbyResolvedAsset } from '../../types/image'
import Permalink from '../../components/Permalink/Permalink'
import { mapIndexed } from 'rambdax'
import React from 'react'

import styles from './HomeBoxes.module.scss'

export type Box = {
  title: string
  description: string
  image: GatsbyResolvedAsset
  gridSize: string
  backgroundColorHEX: string
  backgroundImage: GatsbyResolvedAsset
  list: string
  imageFirst: boolean
  headingColor: string
  textColor: string
  textAlign: string
  buttonText: string
  buttonLink: string
  buttonText2: string
  buttonLink2: string
  disclaimer: string
  disclaimerIcon: GatsbyResolvedAsset
  imageVerticalAlign: string
  linkTheme: 'none' | 'dark'
  textVerticalAlign: 'center'
  isLinkBeforeImage: boolean
  headerMaxWidth: string
}

export type ZoneHomeBoxesProps = {
  __typename: 'StrapiGQL_ComponentZonesHomeBoxes'
  box?: Box[]
}

export default function ZoneHomeBoxes(props: ZoneHomeBoxesProps) {
  const { box } = props
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  const BoxDetailLink = props => {
    return (
      <FreeText
        className={classNames(
          [styles.boxDetailLink, props.className],
          [styles[props.theme], props.theme]
        )}
        color={props.linkColor}
      >
        <Permalink className={styles.linkText} to={props.linkUrl}>
          {props.linkText}
        </Permalink>
        <Icon
          icon={getIconByName('arrow-right')}
          size={24}
          className={styles.arrow}
        />
      </FreeText>
    )
  }

  return (
    <Container>
      <Whitespace type="none">
        <Row>
          {mapIndexed(
            (
              {
                title,
                description,
                image,
                gridSize,
                backgroundColorHEX,
                backgroundImage,
                list,
                imageFirst,
                headingColor,
                textColor,
                textAlign,
                buttonText,
                buttonLink,
                buttonText2,
                buttonLink2,
                disclaimer,
                disclaimerIcon,
                imageVerticalAlign,
                linkTheme,
                textVerticalAlign,
                isLinkBeforeImage,
                headerMaxWidth,
              },
              index
            ) => {
              return (
                <Column
                  size={12}
                  sm={gridSize === 'full' ? 12 : 6}
                  key={`box-${index}`}
                  className={styles.column}
                >
                  <div
                    className={classNames(styles.box, styles[gridSize], {
                      [styles.imageFirst]: imageFirst,
                      [styles.hasBackgroundImage]: backgroundImage,
                    })}
                    style={{
                      backgroundColor: backgroundColorHEX,
                      backgroundImage:
                        backgroundImage &&
                        `url(${backgroundImage.resolved.publicURL})`,
                    }}
                  >
                    <Row>
                      {image && (
                        <Column
                          style={{
                            order: imageFirst ? 0 : 1,
                            alignItems: imageVerticalAlign
                              ? imageVerticalAlign
                              : 'center',
                          }}
                          size={12}
                          sm={gridSize === 'full' ? 6 : 12}
                          className={styles.imgColumn}
                        >
                          <GatsbyImage
                            image={resolveAsImage(image)}
                            alt={title}
                            className={styles.itemImage}
                            loading="lazy"
                          />
                        </Column>
                      )}

                      <Column size={12} sm={gridSize === 'full' ? 6 : 12}>
                        <div
                          className={classNames(styles.textContainer, {
                            [styles.center]: textVerticalAlign === 'center',
                          })}
                          style={{
                            textAlign,
                            backgroundColor:
                              backgroundImage && isMobile && backgroundColorHEX,
                          }}
                        >
                          <div>
                            {title && (
                              <Heading
                                type="lg"
                                className={styles.title}
                                theme={
                                  headingColor && headingColor === 'white'
                                    ? 'dark'
                                    : ''
                                }
                                style={{ maxWidth: headerMaxWidth }}
                              >
                                {title}
                              </Heading>
                            )}
                            <FreeText
                              className={styles.description}
                              color={textColor || 'black'}
                            >
                              {description}
                            </FreeText>
                            {list && !isMobile && (
                              <div
                                className={styles.listing}
                                dangerouslySetInnerHTML={{ __html: list }}
                              />
                            )}
                          </div>
                          {buttonText && buttonLink && gridSize === 'full' && (
                            <BoxDetailLink
                              linkColor={textColor || 'black'}
                              linkUrl={buttonLink}
                              linkText={buttonText}
                              theme={linkTheme}
                            />
                          )}

                          <div className={styles.buttonContainer}>
                            {buttonText &&
                              buttonLink &&
                              gridSize === 'half' &&
                              isLinkBeforeImage && (
                                <BoxDetailLink
                                  linkColor={textColor || 'black'}
                                  linkUrl={buttonLink}
                                  linkText={buttonText}
                                  theme={linkTheme}
                                  className={styles.boxDetailLinkBeforeImg}
                                />
                              )}

                            {buttonText2 &&
                              buttonLink2 &&
                              gridSize === 'half' &&
                              isLinkBeforeImage && (
                                <BoxDetailLink
                                  linkColor={textColor || 'black'}
                                  linkUrl={buttonLink2}
                                  linkText={buttonText2}
                                  theme={linkTheme}
                                  className={styles.boxDetailLinkBeforeImg}
                                />
                              )}
                          </div>

                          {disclaimer && (
                            <div className={styles.disclaimerContainer}>
                              {disclaimerIcon && (
                                <div
                                  className={styles.disclaimerIcon}
                                  style={{
                                    backgroundImage:
                                      disclaimerIcon &&
                                      `url(${disclaimerIcon.resolved.publicURL})`,
                                  }}
                                ></div>
                              )}

                              <FreeText className={styles.disclaimer}>
                                {disclaimer}
                              </FreeText>
                            </div>
                          )}
                        </div>
                      </Column>

                      {buttonText &&
                        buttonLink &&
                        gridSize === 'half' &&
                        !isLinkBeforeImage && (
                          <Column size={12} sm={12} style={{ order: 2 }}>
                            <BoxDetailLink
                              linkColor={textColor || 'black'}
                              linkUrl={buttonLink}
                              linkText={buttonText}
                              theme={linkTheme}
                              className={styles.boxDetailLinkAfterImg}
                            />
                          </Column>
                        )}
                    </Row>
                  </div>
                </Column>
              )
            },
            box || []
          )}
        </Row>
      </Whitespace>
    </Container>
  )
}
