import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace, { WhitespaceProps } from '@algbra/ui/components/Whitespace/Whitespace'
import { HAlign } from '@algbra/ui/enums/align'
import { graphql, useStaticQuery } from 'gatsby'
import { ReactNode } from 'react'
import { News } from '@algbra/ui/zones/News/News'
import styles from './NewsList.module.scss'

export type ZoneNewsListProps = {
  __typename: 'StrapiGQL_ComponentZonesNews'
  id: string
  title: ReactNode
  subtitle: string
  maxArticles?: number
  newsWhitespace: WhitespaceProps['type']
}

export const QUERY_NEWS = graphql`
  query NewsQuery {
    strapiGQL {
      newsArticles(
        publicationState: LIVE
        sort: "order, date"
        where: { newsCategory: { slug: "insights" } }
        limit: 10
      ) {
        ...StrapiGQL_NewsArticlesFragment
        newsImage {
          __typename
          id
          url
          ext
          resolved {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: WEBP
                webpOptions: { quality: 100 }
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`

export default function ZoneNewsList(props: ZoneNewsListProps) {
  const {
    strapiGQL: { newsArticles },
  } = useStaticQuery(QUERY_NEWS)
  return (
    <Whitespace type={props.newsWhitespace}>
      <Container>
        {props.title && (
          <Row hAlign={HAlign.CENTER} className={styles.titleRow}>
            <Heading type="lg" className={styles.title}>
              {props.title}
            </Heading>
            <FreeText innerHTML={props.subtitle}>{props.subtitle}</FreeText>
          </Row>
        )}

        <News
          listClassName={styles.listWrapper}
          itemClassName={styles.itemWrapper}
          list={
            props.maxArticles
              ? newsArticles.slice(0, props.maxArticles)
              : newsArticles
          }
        />
      </Container>
    </Whitespace>
  )
}
