import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import '@algbra/ui/styles/globals.scss'
import { graphql } from 'gatsby'
import styles from './corporateHome.module.scss'
import CorporateHomeZoneRenderer from '../zones/CorporateHomeContentZone'
import PageLayout from '../components/PageLayout/PageLayout'
import { getMetaTags } from '@algbra/utils/metaTags'
import MetaTags from '../components/MetaTags/MetaTags'
import AnnouncementBar from '@algbra/ui/components/AnnouncementBar/AnnouncementBar'

export default function LayoutHome({ pageContext, data, location }) {
  const zones = data.strapiGQL.corporatePage.contentZones
  const title = data.strapiGQL.corporatePage.title

  return (
    <PageLayout theme="corporateHome">
      <div>
        <MetaTags {...getMetaTags(pageContext)} />

        <div className={styles.container}>
          <div className={styles.homeAnnouncement}>
            <AnnouncementBar
              title="SC Ventures makes strategic investment in Algbra, establishes partnership with its UK sustainable finance platform Shoal"
              to="https://www.algbra.com/news/sc-ventures-makes-strategic-investment-in-algbra-establishes-partnership-with-its-uk-sustainable-finance-platform-shoal/"
              target="_blank"
            />
          </div>
          <Container
            fluid={false}
            className={styles.headerContainer}
            role="complementary"
          >
            <Heading level={1} type="xl" className={styles.header}>
              {title}
            </Heading>
          </Container>
        </div>

        {zones && (
          <CorporateHomeZoneRenderer zones={zones} location={location} />
        )}
      </div>
    </PageLayout>
  )
}

export const corporatePageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      corporatePage(id: $id) {
        ...StrapiGQL_CorporatePagesWithContentZones
      }
    }
  }
`
