import React from 'react'
import Heading from '../../components/Heading/Heading'
import Permalink from '../../components/Permalink/Permalink'
import { resolveAsAttachment, resolveAsImage } from '../../resolvers'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { NewsEntity } from '../../types/news'
import styles from './NewsItem.module.scss'

export type NewsItemProps = {
  item: NewsEntity
  isLatest?: boolean
}

export default function NewsItem(props: NewsItemProps) {
  const { item, isLatest } = props
  const {
    title,
    newsCategory,
    newsImage,
    slug,
    hasDetailPage,
    attachment,
    link,
  } = item
  let url = attachment ? resolveAsAttachment(attachment) : link
  const isBlank = !hasDetailPage
  if (hasDetailPage) {
    url = `/news/${slug}/`
  }

  return (
    <article
      className={classNames(styles.post, { [styles.isLatest]: isLatest })}
      itemScope
      itemType="https://schema.org/NewsArticle"
    >
      {newsImage && (
        <Permalink to={url} isExternal={isBlank} isBlank={isBlank}>
          <GatsbyImage
            image={resolveAsImage(newsImage)}
            alt={title}
            className={styles.image}
            itemProp="image"
            loading="lazy"
          />
        </Permalink>
      )}
      <div className={styles.content}>
        <Heading
          level={2}
          type="sm"
          className={styles.title}
          itemProp="headline"
        >
          <Permalink to={url} isExternal={isBlank} isBlank={isBlank}>
            {title}
          </Permalink>
        </Heading>
      </div>
      <div className={styles.tags}>
        <Link to={`/news/category/${newsCategory.slug}`}>
          #{newsCategory.title}
        </Link>
      </div>
    </article>
  )
}
